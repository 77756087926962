// MODULES
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React, { useMemo } from 'react';
// RESOURCES
import PageMeta from 'src/components/organisms/page-meta';
import { RouteE } from 'src/constants/pages.constants';
import { useScreenDetect } from 'src/hooks/useScreenDetect';
import { Grid } from 'src/ui/common/atoms/grid';
import { Typography } from 'src/ui/common/atoms/typography';
import { Button } from 'src/ui/white-labels/techcontemporary/atoms/button';
import { StyledActions, StyledLayout, StyledText, StyledTitle } from './styles';

interface Props {
    message?: string;
}

const TechCustom404: React.FC<Props> = ({ message }) => {
    const { t } = useTranslation(['pages', 'actions']);
    const screen = useScreenDetect();

    const locale = useMemo(
        () => ({
            title: t('404.title', { ns: 'pages' }),
            needHelp: t('404.needHelp', { ns: 'pages' }),
            description: t('404.description', { ns: 'pages' }),
            goToHome: t('navigation.goToHomepage', { ns: 'actions' }),
        }),
        [t]
    );

    return (
        <>
            <PageMeta title={locale.title} />
            <StyledLayout>
                <Grid xs={1} md={2}>
                    <StyledText>
                        <StyledTitle tag="div" variant="title" size={screen.xs ? 'md' : 'xl'} medium>
                            {message || locale.title}
                        </StyledTitle>
                        <Typography size={screen.xs ? 'xs' : 'sm'} tag="div">
                            {message ? '' : locale.description}
                        </Typography>
                        <StyledActions>
                            <Link href={RouteE.HOME}>
                                <a>
                                    <Button size={screen.xs ? 'md' : 'lg'}>{locale.goToHome}</Button>
                                </a>
                            </Link>
                            <Link href={RouteE.FAQ}>
                                <a>
                                    <Button variant="tonal" size={screen.xs ? 'md' : 'lg'}>
                                        {locale.needHelp}
                                    </Button>
                                </a>
                            </Link>
                        </StyledActions>
                    </StyledText>
                </Grid>
            </StyledLayout>
        </>
    );
};

export default TechCustom404;