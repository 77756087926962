import styled from 'styled-components';
import { PageLayout } from 'src/ui/common/layouts/page-layout';
import { Typography } from 'src/ui/common/atoms/typography';
import { media } from 'src/ui/common/mixins/media';
import { FlexBox } from 'src/ui/common/atoms/flex-box';

export const StyledLayout = styled(PageLayout)`
  margin: 40px 0 140px;
  padding: 0 10px;
  overflow: hidden;
  
  ${media.md`
    margin: 90px auto;
  `}  
  
  ${media.lg`
    padding: 0;
    
  `}
`;

export const StyledTitle= styled(Typography)`
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.text};
`;

export const StyledActions = styled(FlexBox)`
  margin-top: 40px;
  
  & > a {
    flex: 1;
  }    
  
  & > a + a {
    margin-left: 10px;
  }  
  
  & a button {
    width: 100%;
  }
  
  ${media.sm`
    & > a {
      flex: unset;
    }
    & a button {
      width: unset;
    }
  `}
`;

export const StyledText = styled.div`
  grid-row: 2;
  margin-top: 100px;
  position: relative;
  z-index: 2;
  margin-left: 10px;
  margin-bottom: 15px;  
  ${media.md`
    grid-row: unset;
  `}
`;

export const StyledImg = styled.div`
  margin-right: -30vw;
  text-align: right;
  
  & img {
    max-width: 100%;
  }

  ${media.md`
    margin-right: 0;
  `}
`;
