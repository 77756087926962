import Head from 'next/head';
import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { useUniverse } from 'src/features/common/universe/hooks/use-universe';

interface IProps {
    title: string;
    description?: string;
    ogImage?: string;
    resizeOgImage?: boolean;
}

const PageMeta: React.FC<IProps> = ({ title, description, resizeOgImage, ogImage }) => {
    const { baseUrl } = useUniverse();

    // eslint-disable-next-line no-nested-ternary
    const ogImageValue = ogImage
        ? resizeOgImage
            ? `${baseUrl}/_next/image?url=${encodeURIComponent(ogImage)}&w=1080&q=75`
            : ogImage
        : null;

    return (
        <Head>
            <title key="metaTitle">{sanitizeHtml(title)}</title>
            <meta property="og:title" key="metaOgTitle" content={sanitizeHtml(title)} />
            {description && <meta name="description" content={sanitizeHtml(description)} />}
            {ogImageValue && <meta property="og:image" key="metaOgImage" content={ogImageValue} />}
        </Head>
    );
};

export default PageMeta;
