// MODULES
import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';

// RESOURCES
import PageMeta from 'src/components/organisms/page-meta';
import { Typography } from 'src/ui/common/atoms/typography';
import { Grid } from 'src/ui/common/atoms/grid';
import { StyledActions, StyledImg, StyledLayout, StyledText, StyledTitle } from 'src/features/common/404/styles';
import { Button } from 'src/ui/common/atoms/button';
import { useScreenDetect } from 'src/hooks/useScreenDetect';
import Link from 'next/link';
import { RouteE } from 'src/constants/pages.constants';

interface Props {
    message?: string;
}

const Custom404: React.FC<Props> = ({ message }) => {
    const { t } = useTranslation(['pages', 'actions']);
    const screen = useScreenDetect();

    const locale = useMemo(() => ({
        title: t('404.title', { ns: 'pages' }),
        needHelp: t('404.needHelp', { ns: 'pages' }),
        description: t('404.description', { ns: 'pages' }),
        goToHome: t('navigation.goToHomepage', { ns: 'actions' })
    }), [t]);

    return (
        <>
            <PageMeta title={locale.title} />
            <StyledLayout>
                <Grid xs={1} md={2}>
                    <StyledText>
                        <StyledTitle tag='div' variant='title' size={screen.xs ? 'md' : 'xl'} medium>
                            {message || locale.title}
                        </StyledTitle>
                        <Typography size={screen.xs ? 'xs' : 'sm'} tag='div'>
                            {message ? '' : locale.description}
                        </Typography>
                        <StyledActions>
                            <Link href={RouteE.HOME}>
                                <a>
                                    <Button variant='white' size={screen.xs ? 'md' : 'lg'}>
                                        {locale.goToHome}
                                    </Button>
                                </a>
                            </Link>
                            <Link href={RouteE.FAQ}>
                                <a>
                                    <Button variant='grey' size={screen.xs ? 'md' : 'lg'}>
                                        {locale.needHelp}
                                    </Button>
                                </a>
                            </Link>
                        </StyledActions>
                    </StyledText>
                    <StyledImg>
                        <img src='/404-bg.svg' />
                    </StyledImg>
                </Grid>
            </StyledLayout>
        </>
    );
};

export default Custom404;
