import React from 'react';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import Custom404 from 'src/features/common/404';
import { PAGES_LOCALES } from 'src/constants/common.constants';
import { useGetUniverseTemplate } from 'src/hooks/use-get-universe-template';
import TechCustom404 from 'src/features/white-labels/techcontemporary/404';

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
    props: {
        ...(await serverSideTranslations(locale ?? 'en', PAGES_LOCALES)),
    },
});

function Error() {
    const { isTechContemporary } = useGetUniverseTemplate();
    return isTechContemporary ? <TechCustom404 />  : <Custom404 />;
}

export default Error;
